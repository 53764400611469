import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  ConfirmationForm,
  ExampleOffers,
  FinancialDetails,
  NoOffersPlaceholder,
  StyledContactForm,
  StyledFooter,
  StyledNavigationBar,
  SummaryHeader,
} from '@/components';
import productKinds from '@/constants/productKinds';
import { IOffer, ProductKind, FormData, Response } from '@/interfaces';
import Layout from '@/styles';

const SummaryLayout = styled.div`
  padding-top: 140px;

  @media (max-width: 991px) {
    padding-top: 0;
  }
`;

const SummaryContainer = styled.div`
  padding-bottom: 100px;

  @media (max-width: 991px) {
    padding-bottom: 0;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Summary = ({ location }: { location: any }): JSX.Element => {
  const [data, setData] = useState<FormData>();
  const [response, setResponse] = useState<Response>();
  const [offers, setOffers] = useState<IOffer[]>([]);
  const [productKind, setProductKind] = useState<ProductKind>();
  const { t } = useTranslation();

  useEffect(() => {
    if (location && location.state) {
      setResponse(location.state.payload.response);
      setData(location.state.payload.data);
      if (data && response) {
        setProductKind(productKinds.find((kind) => kind.value === data.productKind));
        setOffers(response.bankOffers.filter((offer: IOffer) => 'ltv' in offer === true));
      }
    }
  }, [data, location, response]);

  return data && response && productKind
    ? (
      <>
        <Helmet>
          <title>{ t('common:page-titles.summary') }</title>
        </Helmet>
        <Layout>
          <StyledNavigationBar
            className="navbar"
            darkMenuIcon
            initialValue="NONE"
          />
          <SummaryLayout>
            <SummaryContainer>
              <SummaryHeader
                data={data}
                productKind={productKind}
              />
              { offers.length > 0 && (
                <FinancialDetails
                  currency={data.currency}
                  response={response}
                />
              ) }
              { offers.length > 0 ? <ExampleOffers offers={offers} /> : <NoOffersPlaceholder /> }
              <ConfirmationForm
                className={offers.length === 0 ? 'no-offers' : ''}
                data={data}
                subTitle={
                  offers.length > 0
                    ? 'summary:confirmation-form.sub-title'
                    : 'summary:confirmation-form.subtitle-no-offers'
                }
                title={
                  offers.length > 0
                    ? 'summary:confirmation-form.title'
                    : 'summary:confirmation-form.title-no-offers'
                }
              />
            </SummaryContainer>
          </SummaryLayout>
          <StyledContactForm id="contact" />
          <StyledFooter />
        </Layout>
      </>
    )
    : (
      <>
        <Helmet>
          <title>{ t('common:page-titles.summary') }</title>
        </Helmet>
      </>
    );
};

export default Summary;
